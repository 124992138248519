import {Box, Container, Typography} from '@mui/material';
import Grid from "@mui/material/Grid";
import Image from "next/image";

type USP = {
  img: string;
  title: string;
  subtitle: string;
}

export default function Usps() {
  const usps: USP[] = [
    {
      img: "/images/diamond.svg",
      title: "Sveriges lägsta arvode",
      subtitle: "Behåll mer av vinsten – vi erbjuder fasta, låga arvoden utan onödiga mellanhänder."
    },
    {
      img: "/images/eye.svg",
      title: "Fullt stöd från proffs",
      subtitle: "Från värdering och marknadsföring till budgivning, juridik och dokumentation – vi tar hand om allt för en bråkdel av kostnaden hos en traditionell mäklare."
    },
    {
      img: "/images/switch.svg",
      title: "Anpassade tjänster efter dina behov",
      subtitle: "Välj själv hur mycket hjälp du vill ha – vi erbjuder både fullständig förmedling och enbart kontraktskrivning i ett skrivuppdrag om du redan hittat en köpare."
    },
    {
      img: "/images/visit.svg",
      title: "Full kontroll över visningarna",
      subtitle: "Du bestämmer när och hur du vill visa din bostad. Vi ger dig verktygen och stödet för att göra det smidigt och enkelt."
    },
    {
      img: "/images/checkmark.svg",
      title: "Transparens och insyn i hela processen",
      subtitle: "Du har full insyn och kontroll och kan följa varje steg. Du hamnar inte på sidlinjen utan behåller kontrollen."
    },
    {
      img: "/images/calendar.svg",
      title: "Snabb och smidig försäljningsprocess",
      subtitle: "Vi gör det enkelt för dig att sälja – med en smidig process från första steget till avslut."
    }
  ]

  return (
    <Container
      sx={{
        py: {md: "108px", xs: "40px"},
        px: {xs: "24px"}
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          fontSize: {md: "56px", xs: "24px"},
          lineHeight: {md: "70px", xs: "30px"},
          fontWeight: {md: 500, xs: 500},
          mb: {md: "64px", xs: "28px"},
          textAlign: {md: "left", xs: "center"}
        }}
      >
        Varför välja Privatmäklaren?
      </Typography>
      <Grid container
        sx={{
          gap: {md: "48px", xs: "32px"},
          flexDirection: {md: "row", sm: 'row', xs: "column"},
          alignItems: {md: "initial", sm: "initial", xs: "center"},
          justifyContent: {xs: "space-between"}
        }}
      >
        {usps.map((u, index) => (
          <Grid
            key={index}
            item
            sx={{
              textAlign: "center",
              width: {md: "calc((100% - 96px) / 3)", xs: "287px"}
            }}
          >
            <Box
              sx={{
                mb: {md: "24px", xs: "12px"},
              }}
            >
              <Image src={u.img} width={"84"} height={"84"} alt={""}/>
            </Box>
            <Typography
              sx={{
                fontSize: {md: "24px", xs: "16px"},
                lineHeight: {md: "30px", xs: "20px"},
                fontWeight: {md: 800, xs: 800},
                mb: {md: "8px", xs: "8px"}
              }}
            >
              {u.title}
            </Typography>
            <Typography
              sx={{
                fontSize: {md: "20px", xs: "16px"},
                lineHeight: {md: "29px", xs: "23px"},
                fontWeight: {md: 400, xs: 400},
              }}
            >{u.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}