import {Box, Container, List, ListItem, Typography, Grid} from "@mui/material";
import CrossIcon from "../../../public/images/cross-icon.svg"
import Image from "next/image"

export default function Placard() {
  const usps = [
    "Höga arvoden som minskar din vinst",
    "Otydlig kommunikation med mäklaren som skapar frustration",
    "Brist på insyn som gör det svårt att känna sig trygg i processen",
    "Procentarvoden som gör slutkostnaden svår att förutse"
  ]

  return (
    <Container
      sx={{
        textAlign: {md: "center"},
        py: {md: '108px', xs: '40px'},
        px: {xs: "24px"}
      }}
    >
      <Box
        sx={{
          maxWidth: "900px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: {md: "56px", xs: '24px'},
            lineHeight: {md: "70px", xs: '30px'},
            fontWeight: {md: 500, xs: 500},
            mb: {md: "24px", xs: '12px'}
          }}
        >
          Vi förstår utmaningarna med att sälja din bostad
        </Typography>
        <Typography
          sx={{
            fontSize: {md: "24px", xs: '16px'},
            lineHeight: {md: "30px", xs: '20px'},
            fontWeight: {md: 800, xs: 800},
            mb: {md: "24px", xs: "24px"}
          }}
        >
          Att sälja ditt hem kan kännas överväldigande. På Privatmäklaren förstår vi det och gör processen enkel, trygg och transparent.
        </Typography>
        <Typography
          sx={{
            fontSize: {md: "24px", xs: '16px'},
            lineHeight: {md: "30px", xs: '20px'},
            fontWeight: {md: 800, xs: 800},
            mb: {md: "13px", xs: "13px"}
          }}
        >
          Med oss slipper du vanliga fallgropar som:
        </Typography>
        <List>
            {usps.map((usp) => (
              <ListItem
                key={usp}
                sx={{
                  p: 0,
                  pb: {md: "20px", xs: "16px"},
                }}
              >
                <Grid
                  container
                  gap={"12px"}
                  sx={{
                    justifyContent: {md: "center"},
                    alignItems: {md: "center", xs: "center"},
                    flexWrap: {xs: 'nowrap'}
                  }}
                >
                  <Grid item
                    sx={{
                      width: {xs: "24px"}
                    }}
                  >
                    <Image src={CrossIcon} alt="" width={24} height={24} style={{display: "block"}} />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      flexGrow: {md: 'initial', xs: "1"}
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {md: "20px"},
                        lineHeight: {md: '29px'},
                        fontWeight: {md: 400}
                      }}
                    >{usp}</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </List>
      </Box>
    </Container>
  )
}