import {Container, Typography} from "@mui/material";
import {useAppSelector} from "../../redux/store";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Box} from "@mui/system";
import SliderNextButton from "../atoms/SliderNextButtonV2";
import SliderPrevButton from "../atoms/SliderPrevButtonV2";
import * as React from "react";
import ArrowLink from "../atoms/ArrowLink/ArrowLink";
import {Star} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Image from "next/image";
import GLogo from "../../../public/images/g-logo.svg";
import HMLogo from "../../../public/images/hm-logo.svg";

export default function Reviews() {
  const entries = useAppSelector(state => state.main.entries);
  const reviews = entries.filter(e => e.sys.contentType.sys.id === 'testimonial')

  const settings = {
    arrow: true,
    autoplay: false, // true
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    draggable: false,
    focusOnSelect: false,
    centerPadding: '0px',
    infinite: true,
    fade: false,
    nextArrow: <SliderNextButton />,
    prevArrow: <SliderPrevButton />,
    className: 'react__slick__slider__parent',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Container
      sx={{
        py: { md: '108px', xs: '40px' },
        px: { xs: '24px' },
      }}
    >
      <Grid
        container
        sx={{
          columnGap: '.4rem',
          alignItems: 'center',
          mb: '24px',
          rowGap: '4px',
        }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: { md: '20px', xs: '16px' },
              lineHeight: { md: '29px', xs: '23px' },
              fontWeight: { md: 400, xs: 800 },
            }}
          >
            4,9
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: { md: '20px', xs: '16px' },
              lineHeight: { md: '29px', xs: '23px' },
              fontWeight: { md: 400, xs: 800 },
            }}
          >
            i snittbetyg på
          </Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              position: 'relative',
              aspectRatio: 1,
              height: '20px',
            }}
          >
            <Image src={GLogo} alt={'logo'} fill={true} />
          </Box>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: { md: '20px', xs: '16px' },
              lineHeight: { md: '29px', xs: '23px' },
              fontWeight: { md: 400, xs: 800 },
            }}
          >
            och
          </Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              position: 'relative',
              aspectRatio: 186 / 27.2,
              height: '17px',
            }}
          >
            <Image src={HMLogo} alt={'logo'} fill={true} />
          </Box>
        </Grid>
        <Grid item container sx={{ width: 'auto' }}>
          {[1, 2, 3, 4, 5].map((id) => (
            <Grid item key={id}>
              <Box
                sx={{
                  position: 'relative',
                  aspectRatio: 1,
                  height: '20px',
                  width: '20px',
                  fontSize: '20px',
                }}
              >
                <Star
                  sx={{
                    color: '#FABB00',
                    fontSize: '20px',
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Typography
        variant={'h2'}
        sx={{
          fontSize: { md: '56px', xs: '24px' },
          lineHeight: { md: '70px', xs: '30px' },
          fontWeight: { md: 500, xs: 500 },
          mb: { md: '64px', xs: '20px' },
        }}
      >
        Vad säger våra kunder
      </Typography>
      <Box
        sx={{
          mb: '24px',
        }}
      >
        <Slider {...settings}>
          {reviews &&
            reviews.map((review, index) => (
              <Box
                key={index}
                sx={{
                  height: "382px",
                }}
              >
                <Box
                  key={review.sys.id}
                  sx={{
                    pr: '1rem',
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                  }}
                >
                  <Typography
                    sx={{
                      mb: { md: '20px' },
                    }}
                  >
                    {Array(review.fields.stars)
                      .fill(1)
                      .map((value, index) => (
                        <Star
                          key={index}
                          sx={{
                            fill: '#FABB00',
                          }}
                        />
                      ))}
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: "1",
                      overflow: "hidden",
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        background:
                          'linear-gradient(hsla(0,0%,100%,0) 60%,#fff 99%)',
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontSize: { md: '20px', xs: '16px' },
                        lineHeight: { md: '29px', xs: '20px' },
                        fontWeight: { md: 800, xs: 800 },
                        mb: { md: '20px', xs: '8px' },
                      }}
                    >
                      {review.fields.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { md: '16px' },
                        lineHeight: { md: '23px' },
                        fontWeight: { md: 400 },
                        overflow: 'hidden',
                        mb: { md: '20px' },
                      }}
                    >
                      {review.fields.text}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { md: '16px' },
                      lineHeight: { md: '23px' },
                      fontWeight: { md: 400 },
                    }}
                  >
                    {review.fields.customerName} - {review.fields.location}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Slider>
      </Box>
      <ArrowLink title={'Läs fler recensioner'} href={'/omdomen'} />
    </Container>
  );
}