import {Button as MUIButton, Container, Typography} from "@mui/material";
import {Box} from "@mui/system";
import Grid from "@mui/material/Grid";
import Image from "next/image";
import React from "react";
import { useRouter } from 'next/navigation'

export default function Process() {
  const router = useRouter();

  const content = [
    {
      number: 1,
      title: "Kom igång snabbt och enkelt",
      content: "Registrera dig på vår plattform och välj mellan fullständig förmedling eller enbart hjälp med kontraktsskrivning i ett skrivuppdrag. Vid förmedling tar vår fotograf professionella bilder av din bostad, och vi skapar en attraktiv annons som publiceras på de största bostadssajterna (Hemnet, Booli och Bovision)."
    },
    {
      number: 2,
      title: "Visningar och budgivning",
      content: "När annonsen är live börjar spekulanter höra av sig för att boka visningar. Du visar bostaden – vi sköter resten. Vi följer upp spekulanter, hanterar budgivningen och håller dig uppdaterad genom hela processen."
    },
    {
      number: 3,
      title: "Kontrakt och tryggt avslut",
      content: "Efter en lyckad budgivning hanterar vi all dokumentation och upprättar kontraktet enligt överenskommelsen mellan dig och köparen. På tillträdesdagen koordinerar vi med bankerna och ser till att alla finansiella transaktioner genomförs smidigt. När allt är klart och du har fått betalt överlämnas nycklarna till den nya ägaren."
    }
  ]

  return (
    <Box
      sx={{
        backgroundColor: '#ecf1ee',
        py: {md: '140px', xs: "40px"},
        px: {xs: "24px"}
      }}
    >
      <Container>
          <Typography
            variant={"h2"}
            sx={{
              fontSize: {md: "56px", xs: "24px"},
              lineHeight: {md: "70px", xs: "30px"},
              fontWeight: {md: 500, xs: 500},
              mb: {md: "24px", xs: "24px"}
            }}
          >
            Så här går det till – smidigt från start till avslut
          </Typography>
          <Typography
            sx={{
              fontSize: {md: "24px", xs: "16px"},
              lineHeight: {md: "30px", xs: "20px"},
              fontWeight: {md: 800, xs: 800},
              mb: {md: "56px", xs: "36px"}
            }}
            >
            Vi finns här för att göra din bostadsförsäljning enkel, trygg och kostnadseffektiv.
          </Typography>

          <Grid
            container
            sx={{
              gap: {md: "56px", xs: "60px"}
            }}
          >
            <Grid item
              sx={{
                width: {md: "calc((100% - 56px) / 2)", xs: "100%"}
              }}
            >
              <Grid container
                sx={{
                  gap: {md: '32px', xs: "32px"}
                }}
              >
                {content.map((content, index) => (
                  <Grid
                    item
                    key={index}
                    className={"step"}
                  >
                    <Typography
                      sx={{
                        fontSize: {md: "56px", xs: "28px"},
                        lineHeight: {md: "70px", xs: "35px"},
                        fontWeight: {md: 900, xs: 900},
                        color: "#48735C",
                        mb: {md: "8px", xs: "16px"}
                      }}>
                      {content.number}.
                    </Typography>
                    <Typography
                      variant={"h3"}
                      sx={{
                        fontSize: {md: "24px", xs: "16px"},
                        lineHeight: {md: "30px", xs: "20px"},
                        fontWeight: {md: 800, xs: 800},
                        mb: {md: "8px", xs: "8px"}
                      }}
                    >
                      {content.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {md: "20px", xs: "16px"},
                        lineHeight: {md: "29px", xs: "23px"},
                        fontWeight: {md: 400, xs: 400}
                      }}>
                      {content.content}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{
                width: {md: "calc((100% - 56px) / 2)", xs: "100%"},
                justifyContent: {md: "initial", xs: "center"},
                flexDirection: {md: "column", xs: "column"},
                alignItems: {xs: "center"}
              }}
            >
              <Grid
                item
                sx={{
                  position: "relative",
                  aspectRatio: 552 / 442,
                  width: {md: "100%", xs: "210px"},
                  mb: {xs: "32px"}
                }}
              >
                <Image src={"/images/couple.png"} fill={true} alt={""}/>
              </Grid>
              <Typography
                sx={{
                  fontSize: {xs: "16px"},
                  lineHeight: {xs: "20px"},
                  fontWeight: {xs: 800},
                  mb: {xs: "32px"}
                }}
              >
                Redan redo att sälja din bostad?
              </Typography>
              <MUIButton
                variant={'contained'}
                sx={{
                  height: { md: '60px', xs: '60px' },
                  backgroundColor: '#C02D7C',
                  borderRadius: '29px',
                  borderColor: '#C02D7C',
                  borderWidth: { md: '2px', xs: '1px' },
                  p: '30px',
                  fontSize: { md: '24px', xs: '16px' },
                  lineHeight: {md: '35px', xs: "23px"},
                  fontWeight: {md: 500, xs: 500},
                  color: '#fff',
                  fontFamily: 'Lato-Bold',
                  textTransform: 'none',
                  ':hover': {
                    color: "#fff",
                    backgroundColor: '#98155B'
                  }
                }}
                onClick={() => router.push("https://login.privatmaklaren.se/#!/registrera")}
              >
                Registrera nu
              </MUIButton>
            </Grid>
          </Grid>
      </Container>
    </Box>
  )
}