import Accordion from "../molecules/Accordion";
import React, {Fragment} from "react";
import {Container, List, ListItem, Typography} from "@mui/material";
import AccordionV2 from "../molecules/AccordionV2";

export default function Faq() {
  const styles = {
    p: {
      fontSize: { md: '20px', xs: '16px' },
      lineHeight: {md: "29px", xs: "23px"},
      fontWeight: {md: 400, xs: 400},
      mb: {md: "24px", xs: "24px"}
    },
    ul: {
      listStyleType: 'disc',
      p: "0 0 0 2rem",
      mb: {md: "24px", xs: "24px"}
    },
    li: {
      fontSize: { md: '20px', xs: '16px' },
      lineHeight: {md: "29px", xs: "23px"},
      fontWeight: {md: 400, xs: 400},
      p: 0,
      pl: "1rem",
      display: 'list-item'
    }
  }
  const faq = [
    {
      name: 'Varför ska jag välja Privatmäklaren istället för en traditionell mäklare?',
      content: (
        <Typography sx={styles.p}>
          Privatmäklaren erbjuder samma professionella tjänster som en traditionell mäklare. Skillnaden är att du sköter visningarna själv, vilket ger dig full kontroll och flexibilitet. Du betalar ett fast, lågt arvode utan krångliga procentsatser – vilket ger dig mer kvar i plånboken efter avslutad affär.
        </Typography>
      )
    },
    {
      name: 'Hur mycket kan jag spara genom att välja Privatmäklaren?',
      content: (
        <Typography sx={styles.p}>Privatmäklaren använder fasta arvoden istället för procentbaserade mäklararvoden. Det betyder att du alltid vet vad det kostar, oavsett försäljningspris. Många av våra kunder sparar tiotusentals kronor – och vid försäljning av dyrare bostäder kan besparingen vara hundratusentals kronor.</Typography>
      )
    },
    {
      name: 'Får jag lika mycket betalt för min bostad med Privatmäklaren?',
      content: (
        <Fragment>
          <Typography sx={styles.p}>Ja! Det finns ingen statistik som visar att bostäder som säljs med vår modell får lägre slutpriser än de som säljs genom traditionella mäklare.</Typography>
          <Typography sx={styles.p}>Det viktigaste är att nå ut till spekulanter på ett effektivt sätt via de stora bostadssajterna – vilket du gör hos oss precis som hos traditionella mäklare. Eftersom du själv sköter visningarna kan du dessutom vara mer flexibel, vilket ofta leder till fler spekulanter och i många fall ett högre slutpris.</Typography>
        </Fragment>
      )
    },
    {
      name: 'Hur kan allt ingå och vara så proffsigt när det kostar så lite?',
      content: (
        <Fragment>
          <Typography sx={styles.p}>Privatmäklaren använder modern teknik och fokuserar på det som skapar mest värde för dig. Till skillnad från traditionella mäklare lägger vi inte tid på mängder av intagsbesök, utan arbetar effektivt för att ge våra kunder bästa möjliga stöd.</Typography>
          <Typography sx={styles.p}>Hos oss gör alla det de är bäst på:</Typography>
          <List sx={styles.ul}>
            <ListItem sx={styles.li}>Du sköter visningen – eftersom du är expert på din bostad.</ListItem>
            <ListItem sx={styles.li}>Fotografen tar professionella bilder för att lyfta fram bostadens bästa sidor.</ListItem>
            <ListItem sx={styles.li}>Mäklaren och kundtjänst hanterar marknadsföring, budgivning, regler, juridik och all dokumentation av affären.</ListItem>
          </List>
          <Typography sx={styles.p}>Det är ett smart och effektivt upplägg – som gör att vi kan erbjuda en proffsig tjänst till marknadens lägsta arvode.</Typography>
        </Fragment>
      )
    },
    {
      name: 'Måste jag själv sköta visningarna?',
      content: (
        <Typography sx={styles.p}>
          Ja, det är en del av vårt koncept. Du är den som känner din bostad bäst och kan lyfta fram fördelarna för spekulanterna. Vi ger dig allt stöd du behöver för att det ska vara enkelt och smidigt.
        </Typography>
      )
    },
    {
      name: 'Vad händer om jag redan har hittat en köpare?',
      content: (
        <Typography sx={styles.p}>
          Om du redan har en köpare kan vi hjälpa dig med resten av affären genom vårt skrivuppdrag. Vi hanterar all dokumentation, ser till att allt är juridiskt korrekt och koordinerar med banken för en smidig transaktion – till ett fast och lågt pris.
        </Typography>
      )
    },
  ]

  return (
    <Container
      sx={{
        pb: {md: "108px"}
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: {md: '56px', xs: "24px"},
          lineHeight: {md: '70px', xs: "30px"},
          fontWeight: {md: 500, xs: 500},
          mb: {md: "24px", xs: "16px"}
        }}
      >
        Vanliga frågor
      </Typography>
      <AccordionV2
        data={faq}
      />
    </Container>
  )
}