import Container from "@mui/material/Container";
import {Box, Button as MUIButton, Dialog, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ContactForm from "../molecules/ContactForm";
import Grid from "@mui/material/Grid";
import {Star} from "@mui/icons-material";
import GLogo from "../../../public/images/g-logo.svg"
import HMLogo from "../../../public/images/hm-logo.svg"

export default function Hero() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <Box
      component="div"
      className="hero"
      sx={{
        alignItems: 'center',
        minHeight: { md: '512px' },
        width: '100%',
        display: 'flex',
        paddingTop: { md: '120px', xs: '40px' },
        paddingBottom: {md: '70px', xs: '40px' },
        position: "relative",
        backgroundColor: '#ecf1ee'
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          px: '2rem',
          gap: '1rem'
        }}
      >
        <Box
          sx={{
            width: { lg: '50%', md: '70%', sm: '70%', xs: '100%' }
          }}
        >
          <Box
            sx={{
              my: 'auto',
              position: "relative",
            }}
          >
            <Typography
              component="h1"
              className="font-Avenir-Bold"
              sx={{
                fontSize: { md: '56px', sm: '28px', xs: '28px' },
                lineHeight: { md: '70px', sm: '35px', xs: '35px' },
                fontWeight: { md: 900, xs: 900 },
                maxWidth: { md: '600px', xs: '100%' },
                color: 'black',
                marginBottom: {md: '24px', xs: '16px' },
                whiteSpace: 'break-spaces',
                textAlign: { md: 'left', xs: 'center' }
              }}
            >
              Sälj din bostad tryggt och smidigt – med Sveriges lägsta arvode
            </Typography>

            <Box
              sx={{
                position: 'relative',
                display: { lg: 'none', md: 'none', sm: 'none', xs: 'block' },
                width: '100%',
                height: '250px',
                mb: '1.5rem'
              }}
            >
              <Image
                src={`/images/woman-heart.png`}
                layout={"fill"}
                objectFit={"contain"}
                alt={""}
              />
            </Box>

            <Typography
              component="p"
              className="font-Avenir-Regular"
              sx={{
                maxWidth: { md: '600px', xs: '100%' },
                fontSize: { md: '24px', xs: '16px' },
                lineHeight: { md: '30px', xs: '20px' },
                fontWeight: { md: 800, xs: 800 },
                color: 'black',
                marginBottom: { md: '25px', xs: '16px' },
                textAlign: { md: 'left', xs: 'center' }
              }}
            >
              Du visar bostaden – vi sköter resten. Du behåller kontrollen medan vi tar hand om marknadsföring, budgivning och affärens avslut på ett tryggt och smidigt sätt.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'left' },
                mb: {md: '20px', xs: '12px'}
              }}
            >
              <MUIButton
                variant={'contained'}
                onClick={() => openDialog()}
                sx={{
                  height: { md: '64px', xs: '40px' },
                  backgroundColor: '#C02D7C',
                  borderRadius: '29px',
                  borderColor: '#C02D7C',
                  borderWidth: { md: '2px', xs: '1px' },
                  p: '30px',
                  fontSize: { md: '24px', xs: '14px' },
                  lineHeight: {md: '35px'},
                  fontWeight: {md: 500},
                  color: '#fff',
                  fontFamily: 'Lato-Bold',
                  textTransform: 'none',
                  ':hover': {
                    color: "#fff",
                    backgroundColor: '#98155B'
                  }
                }}
              >
                Boka fri rådgivning!
              </MUIButton>
            </Box>

            <Typography
              component="div"
              className="font-Avenir-Regular"
              sx={{
                fontSize: {md: '20px', xs: '16px'},
                lineHeight: {md: '29px', xs: '23px'},
                fontWeight: {md: 400, xs: 800},
                maxWidth: { sm: '600px', xs: '100%' },
                marginBottom: { md: '20px', xs: '12px' },
                textAlign: { md: 'left', xs: 'center' }
              }}
            >
              <Grid container
                sx={{
                  justifyContent: {xs: "center", sm: "flex-start"},
                  columnGap: {sm: ".5rem"}
                }}
              >
                <Grid item>
                  Redan redo att sälja din bostad?
                </Grid>
                <Grid item>
                  <a href='https://login.privatmaklaren.se/#!/registrera' target={"_blank"}>Registrera nu</a>
                </Grid>
              </Grid>
            </Typography>

            <Box
              sx={{
              }}
            >
              <Grid container
                sx={{
                  columnGap: ".4rem",
                  rowGap: {xs: "4px"},
                  justifyContent: { md: 'flex-start', xs: 'center' },
                  alignItems: {md: "center", xs: "center"}
                }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: {md: '20px', xs: '16px'},
                      lineHeight: {md: '29px', xs: '23px'},
                      fontWeight: {md: 400, xs: 800},
                    }}
                  >
                    4,9
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: {md: '20px', xs: '16px'},
                      lineHeight: {md: '29px', xs: '23px'},
                      fontWeight: {md: 400, xs: 800},
                    }}
                  >
                    i snittbetyg på
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      position: 'relative',
                      aspectRatio: 1,
                      height: '20px'
                    }}>
                    <Image src={GLogo} alt={"logo"} fill={true} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: {md: '20px', xs: '16px'},
                      lineHeight: {md: '29px', xs: '23px'},
                      fontWeight: {md: 400, xs: 800},
                    }}
                  >
                    och
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      position: 'relative',
                      aspectRatio: 186 / 27.2,
                      height: '17px'
                    }}>
                    <Image src={HMLogo} alt={"logo"} fill={true} />
                  </Box>
                </Grid>
                <Grid item container sx={{width: 'auto'}}>
                  {[1,2,3,4,5].map(id => (
                    <Grid item key={id}>
                      <Box
                        sx={{
                          position: 'relative',
                          aspectRatio: 1,
                          height: '20px',
                          width: '20px',
                          fontSize: '20px'
                        }}>
                        <Star
                          sx={{
                            color: "#FABB00",
                            fontSize: '20px'
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: { lg: 'block', md: 'block', sm: 'block', xs: 'none' },
            width: { sm: '50%' },
          }}
        >

          <Image
            src={'/images/woman-heart.png'}
            layout={"fill"}
            objectFit={"contain"}
            alt={""}
          />
        </Box>
      </Container>
      <Dialog maxWidth="lg" onClose={closeDialog} open={dialogOpen}>
        <Box
          sx={{
            maxWidth: '600px',
            minWidth: { md: '600px', xs: '270px', sm: '400px' },
            height: '440px',
            px: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir-Bold',
              fontSize: '24px',
              textTransform: 'uppercase',
              mt: '30px',
            }}
          >
            Boka fri rådgivning
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <ContactForm />
        </Box>
      </Dialog>
    </Box>
  )
}