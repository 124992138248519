import Head from "next/head";
import {Box, Button as MUIButton} from "@mui/material";
import Navbar from "../contentful/content-types/Navbar";
import React from "react";
import Hero from "../components/frontPage/Hero";
import Placard from "../components/frontPage/Placard";
import VideoIntroduction from "../components/frontPage/VideoIntroduction";
import Usps from "../components/frontPage/Usps";
import Process from "../components/frontPage/Process";
import Faq from "../components/frontPage/Faq";
import Reviews from "../components/frontPage/Reviews";
import {wrapper} from "../redux/store";
import {
    fetchAssets, fetchBiddingInfo, fetchCoaAssociationTypes,
    fetchEntries,
    fetchHouseAssociationTypes, fetchHouseTypes, fetchMunicipalities,
    fetchObjects, fetchObjectSlugs,
    fetchSoldObjects
} from "../redux/slices/mainSlice";
import axios from "axios";
import createSlug from "../utils/createSlug";
import createUrlFromContext from "../utils/createUrlFromContext";
import reportPageView from "../utils/reportPageView";
import Contact from "../components/frontPage/Contact";
import Footer from "../contentful/content-types/Footer";

export default function Home() {
    return (
        <>
            <Head>
                <title>Privatmäklaren | Sälj din bostad tryggt online – Lågt fast arvode</title>
                <meta name="description" content="Sälj din bostad tryggt och smidigt med Privatmäklaren. Fast, lågt arvode utan krångliga procentsatser. Få fri rådgivning av våra erfarna mäklare redan idag!" />
            </Head>
            <Box sx={{ width: '100%', position: 'fixed', zIndex: '20', top: '0px' }}>
                <Navbar />
            </Box>
            <Box
                component="div"
                sx={{
                    mt: { xs: '60px', lg: '80px' }
                }}
            >
                <Hero/>
                <Placard/>
                <VideoIntroduction/>
                <Usps/>
                <Process/>
                <Reviews/>
                <Faq/>
                <Contact/>
            </Box>
            <Footer />
        </>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(
    (store) => async (context) => {
        await Promise.all([
            store.dispatch(fetchEntries()),
            // TODO: Migrate to fetchPageBySlug instead of fetchEntries
            // store.dispatch(fetchEntriesBySlug(context.query.slug ? slug : '')),
            store.dispatch(fetchAssets()),
            store.dispatch(fetchObjects()),
            store.dispatch(fetchSoldObjects()),
            store.dispatch(fetchHouseAssociationTypes()),
            store.dispatch(fetchCoaAssociationTypes()),
            store.dispatch(fetchMunicipalities()),
            store.dispatch(fetchHouseTypes()),
            store.dispatch(fetchObjectSlugs()),
            store.dispatch(fetchBiddingInfo())
        ])

        return {};
    }
);