import {Box} from "@mui/system";
import Grid from "@mui/material/Grid";
import {Container, Typography} from "@mui/material";
import ArrowLink from "../atoms/ArrowLink/ArrowLink";

export default function VideoIntroduction() {
  return (
    <Box
      sx={{
        backgroundColor: "#ecf1ee",
      }}
    >
      <Container
        sx={{
          py: {md: "140px", xs: "40px"},
          px: {xs: "24px"}
        }}
      >
        <Grid
          container
          sx={{
            wrap: {md: "nowrap"},
            flexDirection: {md: "row"},
            gap: {md: "56px", xs: "32px"}
          }}
        >
          <Grid item
            sx={{
              width: {md: "calc((100% - 56px) / 2)"}
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: {md: "56px", xs: "24px"},
                lineHeight: {md: "70px", xs: "30px"},
                fontWeight: {md: 500, xs: 500},
                mb: {md: "24px", xs: "12px"}
              }}
            >
              Ett smartare sätt att sälja din bostad
            </Typography>
            <Typography
              sx={{
                fontSize: {md: "24px", xs: "16px"},
                lineHeight: {md: "30px", xs: "20px"},
                fontWeight: {md: 800, xs: 800},
                mb: {md: "24px", xs: "12px"}
              }}
            >
              Vi kombinerar vår expertis med din lokalkännedom för en trygg och smidig försäljning.
            </Typography>
            <Typography
              sx={{
                fontSize: {md: "20px", xs: "16px"},
                lineHeight: {md: "29px", xs: "23px"},
                fontWeight: {md: 400, xs: 400},
                mb: {md: "24px", xs: "12px"}
              }}
            >
              Oavsett om du vill ha hjälp med hela förmedlingen eller endast kontraktsskrivning i ett skrivuppdrag, finns vi här för att ge dig rätt stöd. Du sköter visningarna – vi tar hand om resten, från värdering och marknadsföring till juridik och avslut.
            </Typography>
            <Typography
              sx={{
                fontSize: {md: "20px", xs: "16px"},
                lineHeight: {md: "29px", xs: "23px"},
                fontWeight: {md: 400, xs: 400},
              }}
            >
              Vi anpassar oss efter dina behov och erbjuder tydliga, fasta priser. Det ger dig full kontroll och trygghet att sälja ditt hem på dina egna villkor.
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: {md: "calc((100% - 56px) / 2)", xs: "100%"}
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingBottom: {xs: "51%"},
                paddingTop: "30px",
                height: 0,
                overflow: "hidden",
                mb: {xs: "17px"}
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                src="https://player.vimeo.com/video/1060929578?h=3da563e7a5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen></iframe>
            </Box>
            <ArrowLink title={"Läs mer om våra tjänster"} href={"/salja"}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}