import Grid from "@mui/material/Grid";
import {Box} from "@mui/system";
import Link from "@mui/material/Link";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Typography} from "@mui/material";

type ArrowLinkProps = {
  title: string;
  href: string;
}

export default function ArrowLink(props: ArrowLinkProps) {
  const { title, href } = props;
  return (
    <Box>
      <Link
        href={href}
        sx={{
          textDecoration: "none",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: {md: "initial", xs: "center"},
            gap: '.3rem'
          }}
        >
          <Grid item>
            <Typography
              sx={{
                color: "#C02E7C",
                fontSize: {md: "24px", xs: "16px"},
                lineHeight: {md: "35px", xs: "23px"},
                fontWeight: {md: 800, xs: 800},
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              width: 'unset'
            }}
          >
            <ArrowForwardIcon sx={{ color: "#C02E7C" }} />
          </Grid>
        </Grid>
      </Link>
    </Box>
  )
}