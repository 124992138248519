import {Box, Container, Typography} from "@mui/material";
import ManWithHeadset from "../../../public/images/man-with-headset.png"
import Image from "next/image";
import React from "react";
import Grid from "@mui/material/Grid";
import MUILink from "@mui/material/Link";
import Link from "next/link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import executeGrecaptcha from "../../utils/executeGrecaptcha";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Contact() {
  const [successMsg, setSuccessMsg] = React.useState<string>("");
  const [errorMsg, setErrorMsg] = React.useState<string>("");

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    setSuccessMsg("");
    setErrorMsg("");

    const name = formData.get("name")
    const phone = formData.get("phone")

    if (!name || !phone) {
      setErrorMsg("Vänligen ange namn och telefonnr.");
      return
    }

    try {
      executeGrecaptcha("contact", async (token: string) => {
        const wl = window.location
        const recaptchaData = await axios.post(
          `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/contact`,
          {
            captcha: token,
            name,
            phone,
            url: wl.origin + wl.pathname
          }
        );
        if (recaptchaData && recaptchaData.data.success === true) {
          setSuccessMsg('Tack! Vi hör av oss inom kort.');
          window.dataLayer.push({
            event: "submitted_contact_form"
          })
        }
      })
    } catch (error) {
      setErrorMsg('Okänt fel. Vänligen kontakta kundtjänst.');
    }
  }

  return (
    <Box
      sx={{
        py: {md: "140px", xs: "40px"},
        px: {xs: "24px"},
        backgroundColor: "#ecf1ee"
      }}
    >
      <Container>
        <Box
          sx={{
            position: "relative",
            height: "257px",
            mx: "auto",
            aspectRatio: 970/753,
            mb: {md: '56px', xs: "32px"}
          }}
        >
          <Image src={ManWithHeadset} alt={''} fill={true} />
        </Box>
        <Typography
          variant="h2"
          sx={{
            fontSize: {md: '56px', xs: "24px"},
            lineHeight: {md: '70px', xs: "30px"},
            fontWeight: {md: 500, xs: 500},
            mb: {md: '24px', xs: "8px"},
            textAlign: "center"
          }}
        >
          Boka fri rådgivning!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {md: "20px", xs: "16px"},
            lineHeight: {md: '29px', xs: "23px"},
            fontWeight: {md: 400, xs: 400},
            mb: {md: '36px', xs: "20px"},
          }}
        >
          Vill du veta mer om hur vi kan hjälpa dig att sälja din bostad? Fyll i dina uppgifter så kontaktar en av våra experter dig för en kostnadsfri genomgång.
        </Typography>
        {errorMsg && (
          <Typography
            sx={{
              color: "red",
              textAlign: "center",
              fontSize: {md: "20px", xs: "16px"},
              lineHeight: {md: '29px', xs: "23px"},
              fontWeight: {md: 400, xs: 400},
              mb: "1rem"
            }}
          >{errorMsg}</Typography>
        )}
        {successMsg && (
          <Typography
            sx={{
              color: "green",
              textAlign: "center",
              fontSize: {md: "20px", xs: "16px"},
              lineHeight: {md: '26px'},
              fontWeight: {md: 400, xs: 400},
              mb: {md: '36px', xs: "20px"},
            }}
          >
            <Grid
              container
              component={"span"}
              sx={{
                justifyContent: "center",
                columnGap: '8px'
              }}
            >
              <Grid item component={"span"}>
                <CheckCircleIcon/>
              </Grid>
              <Grid item component={"span"}>
                {successMsg}
              </Grid>
            </Grid>
          </Typography>
        )}
        {!successMsg && (
          <form onSubmit={submitForm}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                gap: "1rem",
                mb: {md: "56px", xs: "32px"}
              }}
            >
              <Grid item>
                <TextField
                  name={'name'}
                  placeholder={'Ditt namn'}
                  sx={{
                    width: "316px",
                    backgroundColor: "white",
                    border: "2px solid #AAAAAA",
                    borderRadius: "8px",
                    outline: "none",
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  type={'tel'}
                  name={'phone'}
                  placeholder={'Telefonnummer'}
                  sx={{
                    width: "316px",
                    backgroundColor: "white",
                    border: "2px solid #AAAAAA",
                    borderRadius: "8px",
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  type={'submit'}
                  sx={{
                    backgroundColor: '#c02c7c',
                    borderRadius: '100px',
                    width: "244px",
                    height: "55px",
                    color: "#fff",
                    fontSize: "19px",
                    lineHeight: "34.8px",
                    fontWeight: "400",
                    textTransform: 'none',
                  }}
                >Boka fri rådgivning!</Button>
              </Grid>
            </Grid>
          </form>
        )}
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {md: "20px", xs: "16px"},
            lineHeight: {md: '29px', xs: "23px"},
            fontWeight: {md: 800, xs: 800},
            mb: {md: "24px", xs: "16px"}
          }}
        >
          <Grid container
                component={"span"}
            sx={{
              justifyContent: "center",
              columnGap: {sm: ".5rem"}
            }}
          >
            <Grid item component={"span"}>
              Redo att sälja din bostad?
            </Grid>
            <Grid item component={"span"}>
              <MUILink
                href="https://login.privatmaklaren.se/#!/registrera"
                sx={{
                  color: '#C02C7C',
                  fontWeight: "bold",
                  textDecoration: 'none',
                }}
                component={Link}
                target={"_blank"}
              >
                Registrera dig nu!
              </MUILink>
            </Grid>
          </Grid>
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {md: "20px", xs: "16px"},
            lineHeight: {md: '29px', xs: "23px"},
            fontWeight: {md: 400, xs: 400}
          }}
        >
          Kom igång med Privatmäklaren genom att skapa ett konto. Fyll sedan i informationen om din bostad, så kontaktar vi dig för att ta nästa steg i processen.
        </Typography>
      </Container>
    </Box>
  );
}