import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';

const SliderPrevButton = ({ currentSlide, slideCount, ...props }: any) => (
  <Box
    component="button"
    {...props}
    className={'slick-prev slick-arrow'}
    aria-hidden="true"
    type="button"
    sx={{
      borderRadius: '50%',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 0,
      m: 0,
    }}
  >
    <ArrowBackIosNewIcon
      sx={{ color: '#c02e7c', width: '20px', height: '20px', fontWeight: 'bold' }}
    />
  </Box>
);

export default SliderPrevButton;
