import React from 'react';
import { Box, Typography } from '@mui/material';
import classNames from "classnames";
import ThinArrow from "../../../../public/images/thin-arrow.svg"
import Image from "next/image";

type AccordionProps = {
  data: any;
}

const AccordionV2 = (props: AccordionProps) => {
  const {
    data,
  } = props;
  const [selectedMenu, setSelectedMenu] = React.useState([]);

  const refContainers = React.useRef([]);
  React.useMemo(() => {
    refContainers.current = data.map(() => React.createRef<HTMLDivElement>());
  }, [data]);

  const handleClick = (e, index) => {
    e.preventDefault();
    const selIndex = selectedMenu.indexOf(index);
    let selects = selectedMenu;
    if (selIndex > -1) selects.splice(selIndex, 1);
    else selects = [...selects, index];
    setSelectedMenu([...selects]);
  };

  return (
    <>
      {data.map(
        (item, i) => {
          if (!item.content) return null;

          const arrowClassName = classNames({
            'menu-item-icon-v2': true,
            'menu-item-icon-open-v2': selectedMenu.indexOf(i) > -1
          })

          return (
            <Box
              key={i}
              sx={{
                mb: {md: "32px", xs: "16px"}
              }}
            >
              <Box
                sx={{ display: 'flex', cursor: 'pointer' }}
                onClick={(e) => handleClick(e, i)}
              >
                <Typography
                  component="h3"
                  sx={{
                    my: '5px',
                    fontFamily: 'Avenir',
                    fontSize: { md: '20px', xs: '16px' },
                    lineHeight: {md: "29px", xs: "23px"},
                    fontWeight: {md: 800, xs: 800},
                  }}
                >
                  {item.name}
                </Typography>
                <Box
                  sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}
                  className={arrowClassName}
                >
                  <Image src={ThinArrow} alt={""} width={20} height={20}/>
                </Box>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  maxHeight:
                    selectedMenu.indexOf(i) > -1 &&
                    refContainers.current[i].current !== null
                      ? `${refContainers.current[i].current.offsetHeight}px`
                      : '0px',
                  mt: selectedMenu.indexOf(i) > -1 &&
                  refContainers.current[i].current !== null
                    ? "16px"
                    : '0px',
                }}
                className={'menu-item-content'}
              >
                <Box ref={refContainers.current[i]}>
                  {item.content}
                </Box>
              </Box>
            </Box>
          )
        }
      )}
    </>
  );
};

export default AccordionV2;
